<style>
.nounExplanation {
  width: 300px;
  /* height: 50px; */
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;
  position: fixed;
  top: 80px;
  /* left:0px; */
  left:50px;
  background-color: papayawhip;
  z-index: 999;
}
</style>
<template>
  <div>
      <Form label-position="top">
         <p class="text-orange">
            PS：对账操作仅可以完成上月（含）以前的数据对账！
        </p>
      <Row :gutter="8">
        <i-col span="24">
          <FormItem label="对账状态">
            <RadioGroup
          v-model="executeStatus"
          type="button"
          size="small"
        >
          <Radio
            v-for="item in confirmStatus"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</Radio
          >
        </RadioGroup>
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="记账周期">
          <Row :gutter="8">

            <i-col span="10" class="p-b-5">
        <DatePicker
          placeholder="起始月"
          v-model="startDate"
          type="month"
          size="small"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col span="10" class="p-b-5">
        <DatePicker
          placeholder="结束月"
          type="month"
          size="small"
          v-model="endDate"
          placement="bottom-end"
          style="width: 100%"
        ></DatePicker>
      </i-col>
       <i-col span="4">

<icon id="nounExplanation" type="md-help-circle" size="18" style="color:#2d8cf0;cursor:pointer;margin-left:4px" @click="showNounExplanation"></icon>
            </i-col>
          </Row>
          </FormItem>
        </i-col>
        <i-col span="24" >
          <FormItem label="公司列表">
            <Select v-model="currentCompanyId"
          size="small" >
          <Option v-for="(company,index) in companyArray" :key="index" :value="company.id">{{company.name}}</Option>
        </Select>
          </FormItem>
        </i-col>
        <i-col span="24" >
          <FormItem  label="关键字">
           <Row :gutter="8">
              <i-col span="18">
        <i-input
        v-model="keyword"
          type="text"
          size="small"
          clearable
          placeholder="如：客户、品牌、编码"
        ></i-input>
      </i-col>
      <i-col span="6">
        <Button
          type="primary"
          icon="ios-search"
          long
          size="small"
          :loading="loadSearch"
          @click="search"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
      </i-col>
           </Row>
          </FormItem>
        </i-col>
      </Row>

      <FormItem class="text-right">
      </FormItem>
    </Form>

    <Divider dashed><span class="divider-text">其他操作</span></Divider>
    <Row  :gutter="8">
      <i-col span="24"  class="m-b-5" >
        <Button type="primary" size="small" @click="confirmReconcilliation" v-if="isAuth('transaction_confirmReconciliation')"
          :disabled="feeitemIds.length===0|| notAllowReconciliationQuantity > 0">确认对账</Button>
          <Button type="success" size="small" class="m-l-5" @click="showDownLoadProgrammeModal = true">下载列表</Button>
      </i-col>
    </Row>

     <Modal v-model="showDownLoadProgrammeModal">
      <h3 class="p-b-10" slot="header">请选择下载类型</h3>
      <Row>
        <i-col span="24">
          <RadioGroup v-model="downLoadProgramme" vertical>
            <Radio label="1">
                <span>全部下载</span>
            </Radio>
            <Radio label="2">
                <span>勾选下载</span>
            </Radio>
        </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text" @click="showDownLoadProgrammeModal=false">取消</Button>
        <Button type="primary"  @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getcompanylistbytype } from '@/api/os/company'
import { confirmfeeitems } from '@/api/statement/bill'
import { ParseDate, GetPrevMonth, ParseDateForMonth } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
export default {
  data () {
    return {
      loadSearch: false,
      confirmStatus: [
        { id: -1, name: '全部' },
        { id: 0, name: '未对账' },
        { id: 1, name: '已对账' }
      ],
      companyArray: [],

      keyword: '',
      currentCompanyId: 0,
      executeStatus: -1,
      startDate: '',
      endDate: '',
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1'
    }
  },
  created () {
    this.keyword = this.transactionReconciliationQuery.queryString
    this.executeStatus = this.transactionReconciliationQuery.executeStatus
    this.startDate = this.transactionReconciliationQuery.startDate
    this.endDate = this.transactionReconciliationQuery.endDate
    this.initCompanyArray()
  },
  methods: {
    showNounExplanation (e) {
      if (
        document.getElementsByClassName('nounExplanation').length <
                      1
      ) {
        var serviceDiv = document.createElement('div')
        serviceDiv.className = 'nounExplanation'

        // const title = document.createElement('p')
        // title.innerHTML = '服务费包含：'
        // serviceDiv.appendChild(title)

        const content = document.createElement('p')
        content.innerHTML = '记账周期检索，只会检索出当前时间内已执行的合同(部分执行也会纳入)，当前时间内签约非当前时间执行的合同不会纳入该时间段内的搜索结果'
        serviceDiv.appendChild(content)

        event.target.parentElement.appendChild(serviceDiv)
        // 3秒后自动关闭
        setTimeout(() => {
          if (serviceDiv.parentNode) {
            serviceDiv.parentNode.removeChild(serviceDiv)
          }
        }, 3000)
      } else {
        const serviceDivRemark = document.getElementsByClassName(
          'nounExplanation'
        )
        serviceDivRemark[0].parentNode.removeChild(
          serviceDivRemark[0]
        )
      }
    },
    // 获取交易方公司列表
    initCompanyArray () {
      getcompanylistbytype({ companyId: this.companyId, type: 1 }).then(res => {
        this.companyArray = res
        if (this.companyArray.length) {
          let defaultCompanyId = 0
          if (this.companyArray.filter(x => x.id === this.companyId).length) {
            defaultCompanyId = this.companyArray.filter(x => x.id === this.companyId)[0].id
          } else {
            defaultCompanyId = this.companyArray[0].id
          }
          this.currentCompanyId = this.transactionReconciliationQuery.companyId || defaultCompanyId
        }
        this.searchtransactionReconciliationList()
      })
    },
    search () {
      this.$store.commit('set_reconciliationNew_transactionReconciliationPageNumber', 1)
      this.searchtransactionReconciliationList()
    },
    searchtransactionReconciliationList () {
      var query = {
        companyId: this.currentCompanyId,
        executeStatus: this.executeStatus
      }
      if (this.startDate) { query.startDate = ParseDate(this.startDate) }
      if (this.endDate) { query.endDate = ParseDate(this.endDate) }
      if (this.keyword) { query.queryString = this.keyword }
      // if (this.executeStatus !== -1) { query.executeStatus = this.executeStatus }

      this.$store.commit('set_reconciliationNew_transactionReconciliationQuery', query)
      this.$store.commit('set_reconciliationNew_update', new Date())
    },
    confirmReconcilliation () {
      const that = this
      if (!that.feeitemIds.length) {
        that.$Notice.info({ desc: '请选择要对账的条目！' })
      } else {
        that.$Modal.confirm({
          title: '操作确认',
          content: '<p>是否要将所选条目标记为已对账，确认后<span style="color:red;font-weight:600;">无法再次变更</span>，请谨慎操作！</p>',
          loading: true,
          onOk: () => {
            const query = {
              feeitemListJsonStr: JSON.stringify(that.feeitemIds),
              startDate: that.transactionReconciliationQuery.startDate,
              endDate: that.transactionReconciliationQuery.endDate,
              feeitemType: 1
            }
            if (that.transactionReconciliationQuery.startDate) {
              query.startDate = that.transactionReconciliationQuery.startDate
            }

            // 验证结束时间是否有值，无值则给默认值（上个月）
            const prevMonth = GetPrevMonth(new Date())
            if (that.transactionReconciliationQuery.endDate && (new Date(that.transactionReconciliationQuery.endDate) <= new Date(prevMonth))) {
              query.endDate = that.transactionReconciliationQuery.endDate
            } else {
              query.endDate = prevMonth
            }

            confirmfeeitems(query).then(res => {
              if (res && !res.errcode) {
                that.$store.commit('set_reconciliationNew_feeitemIds', [])
                that.$store.commit('set_reconciliationNew_update', new Date())
              }
              that.$Modal.remove()
            }).catch(() => {
              that.$Modal.remove()
            })
          }
        })
      }
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {}
      if (parseInt(that.downLoadProgramme) === 1) {
        params.companyId = that.transactionReconciliationQuery.companyId
        params.startDate = that.transactionReconciliationQuery.startDate
        params.endDate = that.transactionReconciliationQuery.endDate
        params.queryString = that.transactionReconciliationQuery.queryString
        if (that.transactionReconciliationQuery.executeStatus !== -1) {
          params.executeStatus = that.transactionReconciliationQuery.executeStatus
        }

        params.publisherId = that.publisherId
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.contractIds = that.feeitemIds.join(',')
          params.startDate = that.transactionReconciliationQuery.startDate
          params.endDate = that.transactionReconciliationQuery.endDate
          // // 验证结束时间是否有值，无值则给默认值（上个月）
          // const prevMonth = GetPrevMonth(new Date())
          // if (that.transactionReconciliationQuery.endDate && (new Date(that.transactionReconciliationQuery.endDate) <= new Date(prevMonth))) {
          //   params.endDate = that.transactionReconciliationQuery.endDate
          // } else {
          //   params.endDate = prevMonth
          // }
        }
      }

      that.downLoadExecl(params)
    },
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr = ParseDateForMonth(params.startDate) + '至' + ParseDateForMonth(params.endDate)
        }
      }

      const filename = '交易对账明细(' + dateStr + ').xlsx'
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-dw/v1/dw/verify/exportContractExecute', params, filename)
    }

  },
  computed: {
    companyId () {
      return this.$store.state.reconciliationNew.companyId
    },
    publisherId () {
      return this.$store.state.reconciliationNew.publisherId
    },
    transactionReconciliationQuery () {
      return this.$store.state.reconciliationNew.transactionReconciliationQuery
    },
    notAllowReconciliationQuantity () {
      return (
        this.$store.state.reconciliationNew.notAllowReconciliationQuantity || 0
      )
    },
    feeitemIds () {
      return this.$store.state.reconciliationNew.feeitemIds || []
    }
  }
}
</script>
